import { Flex, Spacer } from "@chakra-ui/react"
import * as React from "react"
import StatComponent from "../StatComponent/StatComponent"
import { Results } from "../../types/results"

interface CampaignStatsProps {
    results: Results
}

const CampaignStats: React.FC<CampaignStatsProps> = ({
    results,
}: CampaignStatsProps): React.ReactElement => {
    return (
        <Flex
            display="flex"
            alignItems="center"
            flexDirection="row"
            marginBottom="50px"
        >
            <Flex>
                <StatComponent
                    value={results.OPENED.toLocaleString()}
                    label="Åpnet"
                    color="blue"
                />
            </Flex>
            <Spacer />
            <Flex>
                <StatComponent
                    value={results.SUCCEEDED.toLocaleString()}
                    label="Lurt"
                    color="red"
                />
            </Flex>
            <Spacer />
            <Flex>
                <StatComponent
                    value={results.REPORTED.toLocaleString()}
                    label="Rapportert"
                    color="green"
                />
            </Flex>
            <Spacer />
            <Flex>
                <StatComponent
                    value={results.UNSUBSCRIBED.toLocaleString()}
                    label="Avmeldt"
                />
            </Flex>
            <Spacer />
            <Flex>
                <StatComponent
                    value={results.FAILED.toLocaleString()}
                    label="Ikke levert"
                />
            </Flex>
        </Flex>
    )
}

export default CampaignStats
