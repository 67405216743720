import * as React from "react"
import { Deliverable } from "../../types/deliverable"
import { Line } from "react-chartjs-2"

interface CampaignChartProps {
    deliverables: Deliverable[]
}

const defaultFormatting = {
    fill: false,
    lineTension: 0,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "round",
    pointBackgroundColor: "rgba(255, 255, 255)",
    pointBorderWidth: 3,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 7,
    pointHitRadius: 10,
}

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    min: 0,
                    max: 1,
                    callback: function (value) {
                        return value.toLocaleString("no-NB", {
                            style: "percent",
                        })
                    },
                },
            },
        ],
    },
    legend: {
        position: "bottom",
    },
}

const CampaignLineChart: React.FC<CampaignChartProps> = ({
    deliverables,
}: CampaignChartProps) => {
    if (deliverables == undefined) {
        return <a> Undefined </a>
    }

    const data = {
        labels: [
            "Bekreft kontonummer",
            "Viktig meding",
            "Bekreft epostaddressen din",
            "BankID utløper",
            "Oppdater passord",
        ],
        datasets: [
            {
                label: "Åpnet",
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "rgb(255, 99, 132)",
                pointBorderColor: "rgb(255, 99, 132)",
                pointHoverBackgroundColor: "rgb(255, 99, 132)",
                pointHoverBorderColor: "rgb(255, 99, 132)",
                data: [0.25, 0.34, 0.34, 0.12, 0.45],
                ...defaultFormatting,
            },
            {
                label: "Lurt",
                backgroundColor: "rgb(54, 162, 235)",
                borderColor: "rgb(54, 162, 235)",
                pointBorderColor: "rgb(54, 162, 235)",
                pointHoverBackgroundColor: "rgb(54, 162, 235)",
                pointHoverBorderColor: "rgb(54, 162, 235)",
                data: [0.15, 0.24, 0.31, 0.8, 0.34],
                ...defaultFormatting,
            },
            {
                label: "Avdekket",
                backgroundColor: "rgba(75,192,192,0.4)",
                borderColor: "rgba(75,192,192,1)",
                pointBorderColor: "rgba(75,192,192,1)",
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                data: [0.5, 0.14, 0.11, 0.4, 0],
                ...defaultFormatting,
            },
        ],
    }

    return <Line data={data} options={options} />
}

export default CampaignLineChart
