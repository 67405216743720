import * as React from "react"
import "./CampaignOverviewCard.scss"
import { useHistory } from "react-router-dom"
import { Campaign } from "../../types/campaign"
import StatusBadge from "../StatusBadge/StatusBadge"

interface CampaignGroupCardProps {
    campaign: Campaign
}

const CampaignOverviewCard: React.FC<CampaignGroupCardProps> = ({
    campaign: { campaignID, name, results },
}: CampaignGroupCardProps) => {
    const history = useHistory()

    const status =
        results?.EXPIRED != 0
            ? "Avsluttet"
            : results?.SENT == 0
            ? "Ikke startet"
            : "Pågående"

    return (
        <div
            className="campaignOverviewContainer"
            onClick={() => {
                history.push(`/kampanje?id=` + campaignID?.uuid)
            }}
        >
            <h1>{name}</h1>
            <StatusBadge status={status} />
        </div>
    )
}

export default CampaignOverviewCard
