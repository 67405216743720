import * as React from "react"
import PhishingCard from "./PhishingCard/PhishingCard"
import one from "../../../assets/img/1.png"
import two from "../../../assets/img/2.png"
import three from "../../../assets/img/3.png"
import "./PhishingInfo.scss"

const PhishingInfo: React.FC = () => {
    return (
        <div className="phish">
            <h1>Hva er phishing?</h1>
            <h3>
                Phishing er en form for nettsvindel hvor en svindler prøver å
                fremstå som en legitim institusjon og prøver å lure informsajon
                ut av målet deres.
            </h3>
            <h2>Hvorfor virker phishing?</h2>
            <h3>
                Den vanligste formen for phishing er at en angriper prøver å
                fremstå som et selskap, ofte gjennom epost, der de prøver å få
                innloggings-informasjon eller annen personlig informasjon om
                offeret. Epostene prøver ofte å gi offeret en følelse av at noe
                er galt, eller at sikkerheten deres har blitt svekket. Dette kan
                for eksempel være at man får opplyst at noen har prøvd å hacke
                kontoen din, og man må bytte passord. Eller at
                betalingsinformasjonen din har blitt utnyttet. Et eksempel kan
                for eksempel være en epost fra noen som utgir seg for å være
                Google, og ber deg om å oppdatere passordet ditt på grunn av et
                hackeforsøk. I realiteten er kontoen din trygg, men blir
                kompromitert ved at man oppgir passord i forsøket på å sette et
                nytt.
            </h3>
            <h2>Hvordan oppdage phishing på epost?</h2>
            <div className="phishCards">
                <PhishingCard
                    title="Fra"
                    content="Hva står i frafeltet? Er dette en epostadresse du kjenner igjen?"
                    img={one}
                />
                <PhishingCard
                    title="Til"
                    content="Er du eneste mottaker av eposten? Hvis ikke, kjenner du igjen de andre adressene som eposten har blitt sendt til?"
                    img={two}
                />
                <PhishingCard
                    title="Innhold"
                    content="Hvordan er språket i eposten? Kommer eposten ut av det blå? Er tonen truende, eller varsler om en umiddelbar risiko?"
                    img={three}
                />
            </div>

            <h2>Vanlige phishingmetoder</h2>
        </div>
    )
}

export default PhishingInfo
