const baseUrl =
    process.env.ENVIRONMENT == "dev"
        ? "http://0.0.0.0:8000"
        : "https://api.antip.no"

async function http<T>(path: string, config: RequestInit): Promise<T> {
    const response = await fetch(`${baseUrl}/${path}`, config)
    if (!response.ok) {
        throw new Error(
            { name: response.status, message: response.statusText }.toString(),
        )
    }
    // may error if there is no body, return empty array
    return response.json().catch(() => ({}))
}

export async function get<T>(path: string, config?: RequestInit): Promise<T> {
    const init = { method: "get", ...config }
    return await http<T>(path, init)
}

export async function post<T, U>(
    path: string,
    body: U,
    config?: RequestInit,
): Promise<T> {
    const c = { ...config, headers: { "Content-Type": "application/json" } }
    const init = { method: "post", body: JSON.stringify(body), ...c }
    return await http<T>(path, init)
}

export async function put<T, U>(
    path: string,
    body: U,
    config?: RequestInit,
): Promise<T> {
    const c = { ...config, headers: { "Content-Type": "application/json" } }
    const init = { method: "put", body: JSON.stringify(body), ...c }
    return await http<T>(path, init)
}

export function getStaticFile(filename: string): string {
    return `${baseUrl}/templates/${filename}.html`
}
