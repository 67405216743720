import * as React from "react"
import { Template } from "../../types/template"
import TemplatePreview from "./TemplatePreview"

interface TemplateContainerProps {
    templates: Template[]
}

const TemplateContainer: React.FC<TemplateContainerProps> = ({
    templates,
}: TemplateContainerProps) => {
    const previews = templates?.map((template) => (
        <TemplatePreview template={template} key={template.templateID.uuid} />
    ))

    return (
        <div className={"templatePreview"}>
            <h2>Epostmaler</h2>
            {previews}
        </div>
    )
}

export default TemplateContainer
