import React = require("react")
import { Flex, Link, Text } from "@chakra-ui/react"
import { Deliverable } from "../../types/deliverable"

interface PreviousInfoProps {
    deliverable?: Deliverable
}

const PreviousInfo: React.FC<PreviousInfoProps> = ({
    deliverable,
}: PreviousInfoProps): React.ReactElement => {
    if (deliverable === undefined) {
        return <></>
    }

    const results = deliverable.results

    const successRate =
        results.SENT > 0 ? (100 * results.SUCCEEDED) / results.SENT : 0

    const date = new Date(deliverable.sent)

    return (
        <Flex flexDirection="column">
            <Text fontSize="lg">
                <b>Forrige epost: </b>
                <Link
                    color="teal.500"
                    href={`https://api.antip.no/templates/${deliverable.template?.name}.html`}
                    isExternal
                >
                    {deliverable.template?.subject}
                </Link>
            </Text>
            <Text fontSize="lg">
                <b>Sendt:</b> {date.toLocaleDateString()}
            </Text>
            <Text fontSize="lg">
                <b>Lurte:</b> {successRate.toFixed(1)}%
            </Text>
        </Flex>
    )
}

export default PreviousInfo
