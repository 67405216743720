// src/store/system/campaign.ts
import { GroupID } from "./group"
import { Results } from "./results"
import { Deliverable } from "./deliverable"

export interface CampaignID {
    uuid: string
}

export interface Campaign {
    name: string
    deliverables: {
        [key: string]: Deliverable
    }
    description: string
    results: Results
    campaignID: CampaignID
    groupID: GroupID
    lastUpdated: string
}

export enum CampaignStatus {
    NOT_STARTED = "Ikke startet",
    ONGOING = "Pågående",
    COMPLETED = "Fullført",
}
