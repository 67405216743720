import * as React from "react"
import "./Resources.scss"
import Hero from "../../components/Hero/Hero"
import email_to_website from "../../../assets/img/email_to_website.png"
import target_audience from "../../../assets/img/target_audience.png"
import email_template from "../../../assets/img/email_template.png"
import email_editing from "../../../assets/img/email_editing.png"
import email_sending from "../../../assets/img/email_sending.png"
import undraw_Opened from "../../../assets/img/undraw_Opened.png"
import video from "../../../assets/img/video.png"
import education from "../../../assets/img/education.png"
import stats_and_time from "../../../assets/img/stats_and_time.png"
import LandingLayout from "./LandingLayout"

const Resources: React.FC = (): React.ReactElement => {
    return (
        <LandingLayout>
            <Hero
                title="Antip - etisk phishing"
                subtitle="Antip forebygger phishing blant dine kunder ved å simulere ekte phishing-angrep."
                image={email_to_website}
                ctaText="Registrer deg nå"
                ctaLink="/signup"
                alignment="left"
            />
            <Hero
                title="Sett opp din målgruppe"
                subtitle="Skreddersy målgrupper etter dine behov. Del kunder etter alder, kjønn, kundeforhold eller lignende."
                image={target_audience}
                ctaText="Administrer målgrupper"
                ctaLink="/signup"
                alignment="right"
            />
            <Hero
                title="Bruk våre maler - eller lag dine egne"
                subtitle="Vi tilbyr flere maler som dekker et stort og variert behov. Hvis du ikke finner noe du liker kan du lage dine egne maler."
                image={email_template}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="left"
            />
            <Hero
                title="Personliggjørig av hver epost"
                subtitle="Antip skaper realistiske phishing-angrep ved å bruke din kundeinformasjon til å personliggjøre hver individuelle epost."
                image={email_editing}
                ctaText="Se hvordan"
                ctaLink="/signup"
                alignment="right"
            />
            <Hero
                title="Send epost til målgruppa"
                subtitle="Antip forebygger phishing blant dine kunder ved å simulere ekte phishing-angrep."
                image={email_sending}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="left"
            />
            <Hero
                title="Track hendelser"
                subtitle="Antip forebygger phishing blant dine kunder ved å simulere ekte phishing-angrep."
                image={undraw_Opened}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="right"
            />
            <Hero
                title="Opplæringsvideoer"
                subtitle="Antip forebygger phishing blant dine kunder ved å simulere ekte phishing-angrep."
                image={video}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="left"
            />
            <Hero
                title="Tilpassede utdanningsløp"
                subtitle="Antip forebygger phishing blant dine kunder ved å simulere ekte phishing-angrep."
                image={education}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="right"
            />
            <Hero
                title="Forstå dine kunder"
                subtitle="Antip forebygger phishing blant dine kunder ved å simulere ekte phishing-angrep."
                image={stats_and_time}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="left"
            />
        </LandingLayout>
    )
}

export default Resources
