import * as React from "react"
import { Tag } from "@chakra-ui/react"
import { CampaignStatus } from "../../types/campaign"

interface StatusTagProps {
    status: CampaignStatus
}

const StatusTag: React.FC<StatusTagProps> = ({
    status,
}: StatusTagProps): React.ReactElement => {
    let color: string

    switch (status) {
        case CampaignStatus.ONGOING:
            color = "green"
            break

        case CampaignStatus.COMPLETED:
            color = "blue"
            break

        case CampaignStatus.NOT_STARTED:
            color = "gray"

        default:
            color = "pink"
    }

    return (
        <Tag size={"lg"} variant="subtle" colorScheme={color}>
            {status}
        </Tag>
    )
}

export default StatusTag
