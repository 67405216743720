import * as React from "react"
import { hot } from "react-hot-loader"
import Home from "./routes/Home/Home"
import PhishingInfo from "./routes/PhishingInfo/PhishingInfo"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import "../assets/scss/App.scss"
import Customers from "./routes/Customers/Customers"
import Attempt from "./routes/Attempt/Attempt"
import Admin from "./routes/Admin/Admin"
import Templates from "./routes/Templates/Templates"
import Editor from "./routes/Editor/Editor"
import Navbar from "./components/Navbar/Navbar"
import Resources from "./routes/Resources/Resources"
import Campaigns from "./routes/Campaigns/Campaigns"
import Campaign from "./routes/Campaign/Campaign"

const App: React.FC = () => {
    return (
        <Router>
            <div className="app">
                <Navbar />
                <div className="main">
                    <Switch>
                        <Route path="/admin">
                            <Admin />
                        </Route>
                        <Route path="/attempt">
                            <Attempt />
                        </Route>
                        <Route path="/editor">
                            <Editor />
                        </Route>
                        <Route exact path="/kampanjer">
                            <Campaigns />
                        </Route>
                        <Route path="/kampanje">
                            <Campaign />
                        </Route>
                        <Route path="/maler">
                            <Templates />
                        </Route>
                        <Route path="/kunder">
                            <Customers />
                        </Route>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/phishing">
                            <PhishingInfo />
                        </Route>
                        <Route path="/ressurser">
                            <Resources />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    )
}

declare let module: Record<string, unknown>

export default hot(module)(App)
