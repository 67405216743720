import * as React from "react"
import { Box, Link } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"

const Logo: React.FC = (props) => {
    const history = useHistory()

    return (
        <Box {...props} onClick={() => history.push("/")}>
            <Link fontSize="2xl" fontWeight="bold" color="teal" as={"i"}>
                Antip
            </Link>
        </Box>
    )
}

export default Logo
