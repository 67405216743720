import * as React from "react"

import {
    Box,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    Stack,
} from "@chakra-ui/react"
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons"
import Logo from "../Logo/Logo"
import NavbarLink from "./NavbarLink"
import firebaseApp from "../../firebase"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { SET_USER } from "../../store/user/types"
import { RootState } from "../../store"
import LoginButton from "../Button/LoginButton"
import { gray } from "../../Colors"

const Links = ["Admin"]
const AdminLinks = ["Kampanjer", "Kunder", "Maler", "Ressurser"]

const Navbar: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const dispatch = useDispatch()

    useEffect(() => {
        firebaseApp.default.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch({
                    type: SET_USER,
                    payload: { email: user.email, loggedIn: true },
                })
            } else {
                dispatch({
                    type: SET_USER,
                    payload: { email: "", loggedIn: false },
                })
            }
        })
    }, [])

    const user = useSelector((state: RootState) => state.user)

    const links = user.loggedIn ? AdminLinks : Links

    return (
        <>
            <Box bg={gray} px={4}>
                <Flex
                    h={16}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <IconButton
                        size={"md"}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={"Open Menu"}
                        display={{ md: !isOpen ? "none" : "inherit" }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={"center"}>
                        <Logo />
                        <HStack
                            as={"nav"}
                            spacing={4}
                            display={{ base: "none", md: "flex" }}
                        >
                            {links.map((link) => (
                                <NavbarLink key={link}>{link}</NavbarLink>
                            ))}
                        </HStack>
                    </HStack>
                    <LoginButton />
                </Flex>

                {isOpen ? (
                    <Box pb={4}>
                        <Stack as={"nav"} spacing={4}>
                            {Links.map((link) => (
                                <NavbarLink key={link}>{link}</NavbarLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    )
}

export default Navbar
