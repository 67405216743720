import { Box, Flex, Heading, Link, Spacer, Text } from "@chakra-ui/react"
import * as React from "react"
import StatComponent from "../StatComponent/StatComponent"
import CampaignOverviewButton from "../Button/CampaignOverviewButton"
import StatusTag from "../Status/StatusTag"
import { Campaign, CampaignStatus } from "../../types/campaign"

interface CampaignOverviewProps {
    campaign: Campaign
}

const CampaignOverview: React.FC<CampaignOverviewProps> = ({
    campaign: {
        name,
        description,
        deliverables,
        results,
        campaignID,
        lastUpdated,
    },
}: CampaignOverviewProps): React.ReactElement => {
    const openRate =
        results.SENT > 0 ? (100 * results.OPENED) / results.SENT : 0
    const successRate =
        results.SENT > 0 ? (100 * results.SUCCEEDED) / results.SENT : 0

    const deliverableList = Object.values(deliverables)
    const activeDeliverableCount = deliverableList.reduce(
        (accumulator, item) =>
            item.status != "NOT_STARTED" ? accumulator + 1 : accumulator,
        0,
    )

    const status =
        lastUpdated === "" ? CampaignStatus.NOT_STARTED : CampaignStatus.ONGOING
    const date = new Date(lastUpdated)

    return (
        <Flex w="100%" margin="auto" flexDirection="column">
            <Heading size="xl">
                <Link color="teal.500" href={`kampanje?id=${campaignID.uuid}`}>
                    {name}
                </Link>
            </Heading>
            <Flex
                display="flex"
                alignItems="center"
                flexDirection="row"
                marginBottom="50px"
            >
                <Box flex="1">
                    <Text>{description}</Text>
                    <Text fontSize="12px">
                        Sist oppdatert: {date.toLocaleDateString()}
                    </Text>
                </Box>
                <Spacer />
                <Box flex="1" display="grid" justifyContent="center">
                    <StatusTag status={status} />
                </Box>
                <Spacer />
                <Flex>
                    <StatComponent
                        value={`${openRate.toFixed(1)}%`}
                        label="Åpnet"
                    />
                </Flex>
                <Spacer />
                <Flex>
                    <StatComponent
                        value={`${successRate.toFixed(1)}%`}
                        label="Klikket"
                    />
                </Flex>
                <Spacer />
                <Flex>
                    <StatComponent
                        value={`${activeDeliverableCount} av ${deliverableList.length}`}
                        label="Utsendte"
                    />
                </Flex>
                <Spacer />
                <Box flex="1" display="grid" justifyContent="center">
                    <CampaignOverviewButton status={status} />
                </Box>
            </Flex>
        </Flex>
    )
}

export default CampaignOverview
