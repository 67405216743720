import { Flex, Heading, Image, Spacer, Text, Link } from "@chakra-ui/react"
import * as React from "react"
import DeliverableImage from "../../../assets/img/deliverable.png"
import CampaignOverviewButton from "../Button/CampaignOverviewButton"
import { VerticalTimelineElement } from "react-vertical-timeline-component"
import MailIcon from "@material-ui/icons/Mail"
import DeleteIcon from "@material-ui/icons/Delete"
import ScheduleIcon from "@material-ui/icons/Schedule"
import { Deliverable } from "../../types/deliverable"

interface TimelineElementProps {
    deliverable: Deliverable
}

const TimelineElement: React.FC<TimelineElementProps> = ({
    deliverable: {
        sent,
        template: { subject },
    },
}: TimelineElementProps): React.ReactElement => {
    const icon = sent ? <MailIcon /> : <ScheduleIcon />
    const date = sent
        ? "Sendt: " + new Date(sent).toLocaleDateString()
        : "Sendes: ..."

    return (
        <VerticalTimelineElement
            iconStyle={{
                background: "rgb(33, 150, 243)",
                color: "#fff",
            }}
            icon={icon}
        >
            <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                marginTop="10px"
                marginBottom="15px"
            >
                <Image src={DeliverableImage} />
                <Flex
                    alignItems="left"
                    margin="1px"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Link>
                        <Heading color={"red"}> {subject} </Heading>
                    </Link>
                    <Text> Sist redigert: ... </Text>
                    <Text> {date} </Text>
                </Flex>
                <Spacer />
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="row"
                >
                    <CampaignOverviewButton status="Start" />
                    <DeleteIcon style={{ fontSize: 40 }} />
                </Flex>
            </Flex>
        </VerticalTimelineElement>
    )
}

export default TimelineElement
