import * as React from "react"
import EmailEditor from "react-email-editor"
import { useRef } from "react"

const Editor: React.FC = () => {
    const emailEditorRef = useRef(null)

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data
            console.log("exportHtml", html)
        })
    }

    const onLoad = () => {
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    return (
        <>
            <div>
                <button onClick={exportHtml}>Export HTML</button>
            </div>

            <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
        </>
    )
}

export default Editor
