import * as firebaseApp from "firebase/app"

import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

import firebase from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyBsbKt9UKNt-g9x9pGUpIgM5iaKgM1DWRo",
    authDomain: "antip-9bcbc.firebaseapp.com",
    projectId: "antip-9bcbc",
    storageBucket: "antip-9bcbc.appspot.com",
    messagingSenderId: "499027570723",
    appId: "1:499027570723:web:ff8f0d97b6ed90a41e0196",
    measurementId: "G-WWZTKFEP1C",
}

firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore()

export const storageRef = firebase.storage().ref()

export const getFirebaseAsset = (
    reference: string,
    callback: (
        value: ((prevState: undefined) => undefined) | undefined,
    ) => void,
) => {
    storageRef
        .child("vipps_epost.png")
        .getDownloadURL()
        .then((url) => {
            callback(url)
        })
        .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case "storage/object-not-found":
                    // File doesn't exist
                    break
                case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break
                case "storage/canceled":
                    // User canceled the upload
                    break
                case "storage/unknown":
                    // Unknown error occurred, inspect the server response
                    break
            }
        })
}

firebaseApp.default.auth().useDeviceLanguage()
export const provider = new firebaseApp.default.auth.GoogleAuthProvider()

export const signIn = async (): Promise<void> => {
    await firebaseApp.default
        .auth()
        .signInWithPopup(provider)
        .catch(function (error) {
            console.error(error)
        })
}

export const signOut = async (): Promise<void> => {
    await firebaseApp.default
        .auth()
        .signOut()
        .catch(function (error) {
            console.error(error)
        })
}

export default firebaseApp
