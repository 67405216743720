import React = require("react")
import { Flex, Text } from "@chakra-ui/react"
import { Deliverable } from "../../types/deliverable"

interface GeneralInfo {
    deliverables: Deliverable[]
    description: string
    recipients: number
}

const GeneralInfo: React.FC<GeneralInfo> = ({
    deliverables,
    description,
    recipients,
}: GeneralInfo): React.ReactElement => {
    const activeCount = deliverables.reduce(
        (accumulator, item) =>
            item.sent != "" ? accumulator + 1 : accumulator,
        0,
    )

    return (
        <Flex flexDirection="column">
            <Text fontSize="lg">
                <b>Målgruppe:</b> {description}
            </Text>
            <Text fontSize="lg">
                <b>Antall mottakere:</b> {recipients}
            </Text>
            <Text fontSize="lg">
                <b>Antall sendte:</b>{" "}
                {`${activeCount} av ${deliverables.length}`}
            </Text>
        </Flex>
    )
}

export default GeneralInfo
