import * as React from "react"
import { Flex } from "@chakra-ui/react"
import Hero from "../Hero/Hero"
import data_analytics from "../../../assets/img/data_analytics.png"
import { gray } from "../../Colors"

const DashboardHeader: React.FC = (): React.ReactElement => {
    return (
        <>
            <Flex
                className="landingBox"
                bg={gray}
                display="flex"
                alignItems="center"
                flexDirection="column"
                w="100%"
                h="600px"
                margin="auto"
                marginBottom="5px"
            >
                <Hero
                    title="54% av dine kunder ble lurt i siste kampanje."
                    subtitle="Det er 20% færre enn forrige runde."
                    image={data_analytics}
                    ctaText="Sett opp en ny kampanje nå"
                    ctaLink="/signup"
                    alignment="right"
                />
            </Flex>
        </>
    )
}

export default DashboardHeader
