import * as React from "react"
import { get } from "../../api/api"
import { Button, useToast } from "@chakra-ui/react"
import { EmailIcon } from "@chakra-ui/icons"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { Deliverable } from "../../types/deliverable"
import { CampaignID } from "../../types/campaign"
import { useState } from "react"

interface ButtonProps {
    campaignID: CampaignID
    deliverable: Deliverable
}

const SendEmailButton: React.FC<ButtonProps> = ({
    campaignID,
    deliverable,
}: ButtonProps) => {
    const user = useSelector((state: RootState) => state.user)
    const toast = useToast()

    if (deliverable == undefined) {
        return <></>
    }

    const [dev, setDev] = useState(deliverable)

    return (
        <Button
            leftIcon={<EmailIcon />}
            colorScheme="teal"
            variant="solid"
            className="btn btn-primary"
            onClick={() => {
                if (user.loggedIn === false) {
                    toast({
                        title: "Du er ikke innlogget",
                        description: "Vennligst logg inn for å sende ut epost.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    get<Deliverable>(
                        `campaign/${campaignID.uuid}/send/${dev?.deliverableID.uuid}`,
                    ).then((r) => setDev(r))

                    toast({
                        title: "Epost sendes ut.",
                        description:
                            "Eposten blir sendt ut til alle kundene i denne kampanjen",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }}
            disabled={dev.sent != ""}
        >
            {dev.sent == "" ? "Send" : "Sendt"}
        </Button>
    )
}

export default SendEmailButton
