import * as React from "react"
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { CampaignStatus } from "../../types/campaign"

interface CampaignOverviewButtonProps {
    status: string
}

const CampaignOverviewButton: React.FC<CampaignOverviewButtonProps> = ({
    status,
}: CampaignOverviewButtonProps): React.ReactElement => {
    let label: string
    const alternatives = ["Se oversikt", "Lag kopi", "Slett"]

    switch (status) {
        case CampaignStatus.NOT_STARTED:
            label = "Start"
            break
        case CampaignStatus.ONGOING:
            label = "Send neste"
            alternatives.push("Stopp")
            break
        case CampaignStatus.COMPLETED:
            label = "Arkiver"
            break
        default:
            label = "Se oversikt"
    }

    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {label}
            </MenuButton>
            <MenuList>
                {alternatives.map((item) => (
                    <MenuItem key={item}>{item}</MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}

export default CampaignOverviewButton
