import { UserState, UserActionTypes, SET_USER } from "./types"

const initialState: UserState = {
    loggedIn: false,
    email: "",
    firstName: "",
    lastName: "",
}

export function userReducer(
    state = initialState,
    action: UserActionTypes,
): UserState {
    switch (action.type) {
        case SET_USER: {
            return {
                ...state,
                ...action.payload,
            }
        }
        default:
            return state
    }
}
