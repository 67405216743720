import * as React from "react"
import { Stat, StatLabel, StatNumber } from "@chakra-ui/react"

interface StatProps {
    value: string
    label: string
    color?: string
}

const StatComponent: React.FC<StatProps> = ({
    value,
    label,
    color,
}: StatProps): React.ReactElement => {
    if (color === undefined) {
        color = "black"
    }

    return (
        <Stat>
            <StatNumber display="grid" justifyContent="center" color={color}>
                {value}
            </StatNumber>
            <StatLabel display="grid" justifyContent="center">
                {label}
            </StatLabel>
        </Stat>
    )
}

export default StatComponent
