import * as React from "react"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"

interface ButtonProps {
    slug: string
    label: string
}

const CreateButton: React.FC<ButtonProps> = ({ label, slug }: ButtonProps) => {
    const history = useHistory()

    return (
        <Button
            leftIcon={<AddIcon />}
            colorScheme="teal"
            variant="solid"
            className="btn btn-primary"
            onClick={() => history.push(slug)}
        >
            {label}
        </Button>
    )
}

export default CreateButton
