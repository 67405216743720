import * as React from "react"
import { useEffect, useState } from "react"
import { db } from "../../firebase"
import CampaignOverview from "./CampaignOverview"
import { Campaign } from "../../types/campaign"
import { Flex, Heading } from "@chakra-ui/react"

const CampaignOverviewContainer: React.FC = (): React.ReactElement => {
    const [campaigns, setCampaigns] = useState<Campaign[]>([])

    useEffect(() => {
        const unsubscribe = db
            .collection("campaign")
            .where("name", "!=", "dummy")
            .onSnapshot((querySnapshot) => {
                const c = []
                querySnapshot.forEach((doc) => {
                    c.push(doc.data())
                })
                setCampaigns(c)
            })
        return () => {
            console.log("Unsubscribing from Firebase")
            unsubscribe()
        }
    }, [])

    return (
        <Flex
            display="flex"
            alignItems="center"
            flexDirection="column"
            w="75%"
            margin="auto"
        >
            <Heading> Slik går dine kampanjer </Heading>
            {campaigns.map((campaign) => (
                <CampaignOverview
                    campaign={campaign}
                    key={campaign.campaignID.uuid}
                />
            ))}
        </Flex>
    )
}

export default CampaignOverviewContainer
