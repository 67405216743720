import * as React from "react"
import { useEffect, useState } from "react"
import "./Campaign.scss"
import { useLocation } from "react-router-dom"
import { Campaign } from "../../types/campaign"
import { get } from "../../api/api"
import { Flex } from "@chakra-ui/react"
import CampaignChartContainer from "../../components/CampaignChart/CampaignChartContainer"
import CampaignLineChart from "../../components/CampaignChart/CampaignLineChart"
import CampaignHeader from "../../components/CampaignHeader/CampaignHeader"
import CampaignStats from "../../components/CampaignOverview/CampaignStats"
import Timeline from "../../components/Timeline/Timeline"

const Campaign: React.FC = () => {
    const search = useLocation().search
    const campaignID = new URLSearchParams(search).get("id")

    const [campaign, setCampaign] = useState<Campaign | undefined>(undefined)

    const getCampaign = async (): Promise<void> => {
        const data = await get<Campaign>(`campaign/${campaignID}`)
        setCampaign(data)
    }

    useEffect(() => {
        ;(async () => {
            await getCampaign()
        })()
    }, [])

    if (campaign == undefined) {
        return <a>Undefined</a>
    }

    const deliverables = Object.values(campaign?.deliverables)

    return (
        <>
            <Flex w="75%" margin="auto" flexDirection="column">
                <CampaignHeader campaign={campaign} />
                <CampaignChartContainer deliverables={deliverables} />
                <CampaignStats results={campaign.results} />
                <CampaignLineChart deliverables={deliverables} />
                <Timeline deliverables={deliverables} />
            </Flex>
        </>
    )
}

export default Campaign
