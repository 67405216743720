import * as React from "react"
import { Button, Flex, Heading, Stack } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const HomeHeader: React.FC = (): React.ReactElement => {
    return (
        <Flex
            className="landingBox"
            bg="#FFBD59"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="100%"
            h="400px"
            margin="auto"
            marginBottom="35px"
        >
            <Stack
                spacing={4}
                w={{ base: "100%", md: "40%" }}
                align={["center", "center", "flex-start", "flex-start"]}
            >
                <Heading
                    as="h1"
                    size="xl"
                    fontWeight="bold"
                    color="primary.800"
                    textAlign={["center", "center", "left", "left"]}
                >
                    Antip forebygger phishing blant dine kunder ved å simulere
                    ekte angrep
                </Heading>
                <Heading
                    as="h2"
                    size="md"
                    color="primary.800"
                    opacity="0.8"
                    fontWeight="normal"
                    lineHeight={1.5}
                    textAlign={["center", "center", "left", "left"]}
                >
                    Personlige eposter til sårbare kundegrupper guider dine
                    kunder gjennom opplæring i hvordan de kan unngå phishing i
                    fremtiden.
                </Heading>
                <Link to="/kampanjer">
                    <Button
                        colorScheme="teal"
                        borderRadius="8px"
                        py="4"
                        px="4"
                        lineHeight="1"
                        size="md"
                    >
                        Sett opp din kampanje
                    </Button>
                </Link>
            </Stack>
        </Flex>
    )
}

export default HomeHeader
