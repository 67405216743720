import * as React from "react"
import { Deliverable } from "../../types/deliverable"
import { Flex, Spacer } from "@chakra-ui/react"
import CampaignHorizontalBarChart from "./CampaignHorizontalBarChart"

interface CampaignHorizontalBarChartProps {
    deliverables: Deliverable[]
}

const CampaignChartContainer: React.FC<CampaignHorizontalBarChartProps> = ({
    deliverables,
}: CampaignHorizontalBarChartProps) => {
    if (deliverables == undefined) {
        return <a> Undefined </a>
    }

    return (
        <Flex w="90%" align="center" justify="center">
            <CampaignHorizontalBarChart label="Lurte" data={[0.45, 0.57]} />
            <Spacer />
            <CampaignHorizontalBarChart label="Avdekkede" data={[0.56, 0.23]} />
        </Flex>
    )
}

export default CampaignChartContainer
