import { Customer } from "../../types/customer"
import * as React from "react"

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
} from "@chakra-ui/react"

interface CustomerTableProps {
    customers: Customer[]
}

const CustomerTable: React.FC<CustomerTableProps> = ({
    customers,
}: CustomerTableProps) => {
    if (customers == undefined) {
        return (
            <tr key="empty">
                <td>Ingen kunder...</td>
                <td> </td>
                <td> </td>
            </tr>
        )
    }

    const customerList = customers.map((customer) => (
        <Tr key={customer.email}>
            <Td>{customer.firstName}</Td>
            <Td>{customer.lastName}</Td>
            <Td>{customer.email}</Td>
        </Tr>
    ))

    const labels = (
        <Tr>
            <Th>Fornavn</Th>
            <Th>Etternavn</Th>
            <Th>Epost</Th>
        </Tr>
    )

    return (
        <>
            <h1> Kundeliste </h1>
            <Table variant="simple">
                <Thead>{labels}</Thead>
                <Tbody>{customerList}</Tbody>
                <Tfoot>{labels}</Tfoot>
            </Table>
        </>
    )
}

export default CustomerTable
