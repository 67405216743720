import * as React from "react"
import "./Home.scss"
import HomeHeader from "../../components/Home/HomeHeader"
import HomeBody from "../../components/Home/HomeBody"

const Home: React.FC = (): React.ReactElement => {
    return (
        <div className="homeDiv">
            <HomeHeader />
            <HomeBody />
        </div>
    )
}

export default Home
