import * as React from "react"
import "./Campaigns.scss"
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader"
import CampaignOverviewContainer from "../../components/CampaignOverview/CampaignOverviewContainer"

const Campaigns: React.FC = (): React.ReactElement => {
    return (
        <div className="homeDiv">
            <DashboardHeader />
            <CampaignOverviewContainer />
        </div>
    )
}

export default Campaigns
