import * as React from "react"
import { post } from "../../api/api"
import { Button, useToast } from "@chakra-ui/react"
import { EmailIcon } from "@chakra-ui/icons"
import { Customer } from "../../types/customer"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { TemplateID } from "../../types/template"

interface ButtonProps {
    templateID: TemplateID
}

const TestEmailButton: React.FC<ButtonProps> = ({
    templateID,
}: ButtonProps) => {
    const user = useSelector((state: RootState) => state.user)
    const toast = useToast()

    return (
        <Button
            leftIcon={<EmailIcon />}
            colorScheme="teal"
            variant="solid"
            className="btn btn-primary"
            onClick={() => {
                if (user.loggedIn === false) {
                    toast({
                        title: "Du er ikke innlogget",
                        description:
                            "Vennligst logg inn for å teste utsending.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    post<never, Customer>(
                        `template/${templateID.uuid}/send/`,
                        user,
                    ).then((r) => console.log(r))

                    toast({
                        title: "Epost sendt.",
                        description:
                            "En epost basert på denne malen er sendt til din personlige adresse.",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }}
        >
            Test utsending
        </Button>
    )
}

export default TestEmailButton
