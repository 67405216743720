// src/store/system/customer.ts
export interface UserState {
    loggedIn: boolean
    email: string
    firstName: string
    lastName: string
}

export const SET_USER = "SET_USER"

interface UpdateSessionAction {
    type: typeof SET_USER
    payload: UserState
}

export type UserActionTypes = UpdateSessionAction
