import React = require("react")
import { Flex, Link, Text } from "@chakra-ui/react"
import { Deliverable } from "../../types/deliverable"
import SendEmailButton from "../Button/SendEmailButton"
import { CampaignID } from "../../types/campaign"

interface NextInfoProps {
    campaignID: CampaignID
    deliverable?: Deliverable
}

const NextInfo: React.FC<NextInfoProps> = ({
    campaignID,
    deliverable,
}: NextInfoProps): React.ReactElement => {
    if (deliverable === undefined) {
        return <></>
    }

    return (
        <Flex flexDirection="column">
            <Text fontSize="lg">
                <b>Neste epost: </b>
                <Link
                    color="teal.500"
                    href={`https://api.antip.no/templates/${deliverable.template?.name}.html`}
                    isExternal
                >
                    {deliverable.template?.subject}
                </Link>
            </Text>
            <Text fontSize="lg">
                <b>Sendes:</b> MISSING
            </Text>
            <SendEmailButton
                campaignID={campaignID}
                deliverable={deliverable}
            />
        </Flex>
    )
}

export default NextInfo
