import * as React from "react"
import { Box, Button, Heading, Image, Stack } from "@chakra-ui/react"

interface HeroImageProps {
    image: string
}

const HeroImage: React.FC<HeroImageProps> = ({
    image,
}: HeroImageProps): React.ReactElement => {
    return (
        <Box
            w={{ base: "80%", sm: "60%", md: "50%" }}
            mb={{ base: 12, md: 0 }}
            minHeight="50vh"
        >
            <Image src={image} maxH="400px" w="auto" rounded="1rem" />
        </Box>
    )
}

export default HeroImage
