import {
    OrganizationState,
    OrganizationActionTypes,
    SET_ORGANIZATION,
} from "./types"

const initialState: OrganizationState = {
    name: "",
    groups: [],
}

export function organizationReducer(
    state = initialState,
    action: OrganizationActionTypes,
): OrganizationState {
    switch (action.type) {
        case SET_ORGANIZATION: {
            return {
                ...state,
                ...action.payload,
            }
        }
        default:
            return state
    }
}
