import * as React from "react"
import Hero from "../Hero/Hero"
import LandingLayout from "../../routes/Resources/LandingLayout"
import email_to_website from "../../../assets/img/email_to_website.png"
import email_editing from "../../../assets/img/email_editing.png"
import education from "../../../assets/img/education.png"
import stats_and_time from "../../../assets/img/stats_and_time.png"
import { Heading } from "@chakra-ui/react"

const HomeBody: React.FC = (): React.ReactElement => {
    return (
        <LandingLayout>
            <Heading marginBottom="30px">
                Vi hjelper deg å forbedre nettvett blant kundene dine{" "}
            </Heading>
            <Hero
                title="Nå kundene du mener trenger opplæring"
                subtitle="Lag målrettede kampanjer som treffer dine kunders behov for opplæring. "
                image={email_to_website}
                ctaText="Registrer deg nå"
                ctaLink="/signup"
                alignment="left"
            />
            <Hero
                title="Sett opp automatiserte opplæringsløp"
                subtitle="Kombiner ferdig utformede maler med personlig informasjon for både epost og sms."
                image={email_editing}
                ctaText="Administrer målgrupper"
                ctaLink="/signup"
                alignment="left"
            />
            <Hero
                title="Lær kundene dine hvordan de bør håndtere phishingangrep"
                subtitle="Sett opp landingssider etter enkle maler, som lærer opp kunden i godt nettvett."
                image={education}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="left"
            />
            <Hero
                title="Se effekten av utdanningsløpene"
                subtitle="Vi gir deg oppdaterte rapporter på hvordan de ulike målgruppene håndterer angrepene."
                image={stats_and_time}
                ctaText="Register deg nå"
                ctaLink="/signup"
                alignment="left"
            />
        </LandingLayout>
    )
}

export default HomeBody
