import { Flex, Heading } from "@chakra-ui/react"
import * as React from "react"
import "./Timeline.scss"
import { VerticalTimeline } from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import TimelineElement from "./TimelineElement"
import { Deliverable } from "../../types/deliverable"

interface TimelineProps {
    deliverables: Deliverable[]
}

const Timeline: React.FC<TimelineProps> = ({
    deliverables,
}: TimelineProps): React.ReactElement => {
    if (deliverables == undefined) return

    const timelineElements = deliverables.map((deliverable) => (
        <TimelineElement
            deliverable={deliverable}
            key={deliverable.deliverableID.uuid}
        />
    ))

    return (
        <Flex
            w="100%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            margin="auto"
        >
            <Heading>Tidslinje</Heading>
            <VerticalTimeline
                layout="1-column-left"
                animate={false}
                className="vertical-timeline-custom-line"
            >
                {timelineElements}
            </VerticalTimeline>
        </Flex>
    )
}

export default Timeline
