import * as React from "react"
import { render } from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { createStore } from "redux"
import { rootReducer } from "./store"
import { ChakraProvider } from "@chakra-ui/react"

const rootEl = document.getElementById("root")

const store = createStore(rootReducer)

render(
    <BrowserRouter>
        <ChakraProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </ChakraProvider>
    </BrowserRouter>,
    rootEl,
)
