import * as React from "react"
import "./Customers.scss"
import { useEffect, useState } from "react"
import { get } from "../../api/api"
import { Customer } from "../../types/customer"
import CustomerTable from "../../components/CustomerTable/CustomerTable"

const Customers: React.FC = (): React.ReactElement => {
    const [customers, setCustomers] = useState<Customer[] | undefined>(
        undefined,
    )

    const getCustomers = async (): Promise<void> => {
        const data = await get<Customer[]>("customer")
        setCustomers(data)
    }

    useEffect(() => {
        ;(async () => {
            await getCustomers()
        })()
    }, [])

    return (
        <div className="homeContainer">
            <CustomerTable customers={customers} />
        </div>
    )
}

export default Customers
