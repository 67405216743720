// src/store/system/customer.ts
export interface OrganizationState {
    name: string
    groups: Group[]
}

export interface GroupMember {
    name: string
    email: string
}

export interface Group {
    id: number
    name: string
    labels: string[]
    data: number[]
    groupMembers: GroupMember[]
}

export interface GroupDto {
    name: string
    groupMembers: GroupMember[]
}

export const SET_ORGANIZATION = "SET_ORGANIZATION"

interface UpdateSessionAction {
    type: typeof SET_ORGANIZATION
    payload: OrganizationState
}

export type OrganizationActionTypes = UpdateSessionAction
