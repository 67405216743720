import * as React from "react"
import "./PhishingInfo.scss"

interface HomeCardProps {
    img: string
    title: string
    content: string
}

const HomeCard: React.FC<HomeCardProps> = (
    props: HomeCardProps,
): React.ReactElement => {
    return (
        <div className="phishingMainContainer">
            <div>
                <img className="img" src={props.img} />
            </div>
            <div className="separator"></div>
            <div>
                <h1 className="title">{props.title}</h1>
            </div>
            <div>
                <h1 className="content">{props.content}</h1>
            </div>
        </div>
    )
}

export default HomeCard
