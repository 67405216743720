import * as React from "react"
import { HorizontalBar } from "react-chartjs-2"
import "./CampaignHorizontalBarChart.scss"

interface CampaignHorizontalBarChartProps {
    label: string
    data: number[]
}

const CampaignHorizontalBarChart: React.FC<CampaignHorizontalBarChartProps> = ({
    label,
    data,
}: CampaignHorizontalBarChartProps) => {
    const options = {
        title: {
            display: true,
            text: label,
        },
        legend: {
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    display: true,
                    ticks: {
                        min: 0,
                        max: 1,
                        callback: function (value) {
                            return value.toLocaleString("no-NB", {
                                style: "percent",
                            })
                        },
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                },
            ],
        },
    }

    const dataset = {
        labels: ["Kunder", "Gjennomsnitt"],
        datasets: [
            {
                stack: label,
                label: `% ${label}`,
                data: data,
                backgroundColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                ],
                borderWidth: 1,
                maxBarThickness: 25,
            },
        ],
    }

    return (
        <div className="chartContainer">
            <HorizontalBar data={dataset} options={options} />
        </div>
    )
}

export default CampaignHorizontalBarChart
