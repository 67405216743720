import * as React from "react"
import { Link } from "react-router-dom"
import { Button, Heading, Stack } from "@chakra-ui/react"

interface HeroTextProps {
    title: string
    subtitle: string
    ctaText: string
    ctaLink: string
}

const HeroText: React.FC<HeroTextProps> = ({
    title,
    subtitle,
    ctaLink,
    ctaText,
}: HeroTextProps) => {
    return (
        <Stack
            spacing={4}
            w={{ base: "80%", md: "40%" }}
            align={["center", "center", "flex-start", "flex-start"]}
        >
            <Heading
                as="h1"
                size="xl"
                fontWeight="bold"
                color="primary.800"
                textAlign={["center", "center", "left", "left"]}
            >
                {title}
            </Heading>
            <Heading
                as="h2"
                size="md"
                color="primary.800"
                opacity="0.8"
                fontWeight="normal"
                lineHeight={1.5}
                textAlign={["center", "center", "left", "left"]}
            >
                {subtitle}
            </Heading>
            <Link to={ctaLink}>
                <Button
                    colorScheme="teal"
                    borderRadius="8px"
                    py="4"
                    px="4"
                    lineHeight="1"
                    size="md"
                >
                    {ctaText}
                </Button>
            </Link>
        </Stack>
    )
}

export default HeroText
