import * as React from "react"
import TemplateContainer from "../../components/Template/TemplateContainer"
import { useEffect, useState } from "react"
import { get } from "../../api/api"
import { Template } from "../../types/template"

const Templates: React.FC = () => {
    const [templates, setTemplates] = useState<Template[] | undefined>()

    const getTemplates = async (): Promise<void> => {
        const data = await get<Template[]>("template")
        setTemplates(data)
    }

    useEffect(() => {
        ;(async () => {
            await getTemplates()
        })()
    }, [])

    return <TemplateContainer templates={templates} />
}

export default Templates
