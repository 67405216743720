import * as React from "react"
import "./Admin.scss"
import { useEffect, useState } from "react"
import { Campaign } from "../../types/campaign"
import { get } from "../../api/api"
import CampaignCardContainer from "../../components/CampaignOverviewCard/CampaignCardContainer"
import CreateButton from "../../components/Button/CreateButton"
import { Heading } from "@chakra-ui/react"

const Admin: React.FC = () => {
    const [campaigns, setCampaigns] = useState<Campaign[] | undefined>(
        undefined,
    )

    const getCampaigns = async (): Promise<void> => {
        const data = await get<Campaign[]>("campaign")
        setCampaigns(data)
    }

    useEffect(() => {
        ;(async () => {
            await getCampaigns()
        })()
    }, [])

    return (
        <div className="adminPageContainer">
            <Heading as="h1" size="2xl" mb="2">
                Administrasjonsportal
            </Heading>
            <Heading as="h2" size="xl" mb="2">
                Kampanjer
            </Heading>
            <CampaignCardContainer campaigns={campaigns} />
            <CreateButton slug={"/addgroup"} label={"Lag ny kampanje"} />
        </div>
    )
}

export default Admin
