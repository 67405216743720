import React = require("react")
import { Flex, Heading, Spacer } from "@chakra-ui/react"
import { Campaign } from "../../types/campaign"
import PreviousInfo from "./PreviousInfo"
import NextInfo from "./NextInfo"
import GeneralInfo from "./GeneralInfo"

interface CampaignHeaderProps {
    campaign: Campaign
}

const CampaignHeader: React.FC<CampaignHeaderProps> = ({
    campaign: {
        name,
        description,
        deliverables,
        results,
        campaignID,
        lastUpdated,
    },
}: CampaignHeaderProps): React.ReactElement => {
    const deliverableList = Object.values(deliverables)

    const previous = deliverableList.filter(
        (deliverable) =>
            deliverable.sent == lastUpdated && deliverable.sent != "",
    )[0]

    const next = deliverableList.filter(
        (deliverable) => deliverable.sent == "",
    )[0]

    return (
        <>
            <Heading> {name} </Heading>
            <Flex
                display="flex"
                alignItems="center"
                flexDirection="row"
                marginBottom="50px"
            >
                <GeneralInfo
                    deliverables={deliverableList}
                    description={description}
                    recipients={results.CREATED}
                />
                <Spacer />
                <PreviousInfo deliverable={previous} />
                <Spacer />
                <NextInfo campaignID={campaignID} deliverable={next} />
            </Flex>
        </>
    )
}

export default CampaignHeader
