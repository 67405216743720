import * as React from "react"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import "./Attempt.scss"
import { Attempt } from "../../types/attempt"
import { get } from "../../api/api"
import { getFirebaseAsset } from "../../firebase"
import LinkButton from "../../components/Button/LinkButton"
import { Heading } from "@chakra-ui/react"

const Attempt: React.FC = (): React.ReactElement => {
    const search = useLocation().search
    const attemptId = new URLSearchParams(search).get("id")

    const [attempt, setAttempt] = useState<Attempt | undefined>(undefined)
    const getAttempt = async (): Promise<void> => {
        const data = await get<Attempt>(`attempt/${attemptId}/status/succeeded`)
        setAttempt(data)
    }

    const [image, setImage] = useState()
    getFirebaseAsset("vipps_epost.png", setImage)

    useEffect(() => {
        ;(async () => {
            await getAttempt()
        })()
    }, [])

    return (
        <div className="fooled">
            <Heading> Du har blitt lurt av en phishing-epost!</Heading>
            <Heading as="h5" size="md">
                {" "}
                Heldigvis er dette bare en øvelse{" "}
            </Heading>
            <img src={image} width="900px" alt={"phishing"} />
            <h2>
                Se etter disse tingene neste gang du får en mistenkelig epost:
            </h2>
            <ul className="list">
                <li>Kom denne eposten ut av det blå?</li>
                <li>Kontrollerte du avsender av eposten?</li>
                <li>Hvordan var innholdet i eposten, hang alt på greip?</li>
            </ul>

            <LinkButton slug="/phishing" label="Lær mer om phishing" />
        </div>
    )
}

export default Attempt
