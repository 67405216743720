import { userReducer } from "./user/reducer"
import { organizationReducer } from "./organization/reducer"
import { combineReducers } from "redux"

export const rootReducer = combineReducers({
    user: userReducer,
    organization: organizationReducer,
})

export type RootState = ReturnType<typeof rootReducer>
