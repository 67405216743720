import * as React from "react"
import { Flex } from "@chakra-ui/react"

interface LandingLayoutProps {
    children: never
}

const LandingLayout: React.FC = (props: LandingLayoutProps) => {
    return (
        <Flex
            className="landingLayout"
            direction="column"
            align="center"
            maxW={{ xl: "1200px" }}
            m="0 auto"
            {...props}
        >
            {props.children}
        </Flex>
    )
}

export default LandingLayout
