import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import firebaseApp, { signIn, signOut } from "../../firebase"
import { SET_USER } from "../../store/user/types"
import { RootState } from "../../store"
import { useHistory } from "react-router-dom"
import {
    Avatar,
    Button,
    HStack,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Spacer,
} from "@chakra-ui/react"

const LoginButton: React.FC = () => {
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.user)
    const history = useHistory()

    useEffect(() => {
        firebaseApp.default.auth().onAuthStateChanged((user) => {
            if (user) {
                const [firstName, lastName] = user.displayName.split(" ")

                dispatch({
                    type: SET_USER,
                    payload: {
                        email: user.email,
                        loggedIn: true,
                        firstName: firstName,
                        lastName: lastName,
                    },
                })
            } else {
                dispatch({
                    type: SET_USER,
                    payload: {
                        email: "",
                        loggedIn: false,
                        firstName: "",
                        lastName: "",
                    },
                })
            }
        })
    }, [])

    const login = (
        <HStack spacing={3} alignItems={"center"}>
            <Button
                className="loggin"
                variant="outline"
                colorScheme="teal"
                onClick={async () => {
                    await signIn()
                    history.push("/kampanjer")
                }}
            >
                Logg inn
            </Button>
            <Spacer />
            <Button
                className="signup"
                colorScheme="teal"
                onClick={async () => {
                    await signIn()
                    history.push("/kampanjer")
                }}
            >
                Registrer deg
            </Button>
        </HStack>
    )

    const logout = (
        <HStack spacing={3} alignItems={"center"}>
            <Menu>
                <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                >
                    <Avatar
                        size={"sm"}
                        src={
                            "https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                        }
                    />
                </MenuButton>
                <MenuList>
                    <MenuItem>Innstillinger</MenuItem>
                    <MenuItem>Varslinger</MenuItem>
                    <MenuDivider />
                    <MenuItem>Logg ut</MenuItem>
                </MenuList>
            </Menu>
            <Button
                className="logout"
                colorScheme="teal"
                onClick={async () => {
                    await signOut()
                    history.push("/")
                }}
            >
                Logg ut
            </Button>
        </HStack>
    )

    return user.loggedIn ? logout : login
}

export default LoginButton
