import * as React from "react"
import "./TemplatePreview.scss"
import { Template } from "../../types/template"
import { getStaticFile } from "../../api/api"
import TestEmailButton from "../Button/TestEmailButton"

interface TemplatePreviewProps {
    template: Template
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
    template: { name, subject, templateID },
}: TemplatePreviewProps) => {
    return (
        <div className={"templatePreview"}>
            <h2>Forhåndsvisning av: {subject}</h2>
            <h3>ID: {templateID.uuid}</h3>

            <iframe
                className={"templateIframe"}
                src={getStaticFile(name)}
                width={"100%"}
                height={"100%"}
            />
            <a>Test malen ved å send den til deg selv</a>
            <TestEmailButton templateID={templateID} />
        </div>
    )
}

export default TemplatePreview
