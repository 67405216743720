import * as React from "react"
import { Badge } from "@chakra-ui/react"

interface StatusBadgeProps {
    status: string
}

const StatusBadge: React.FC<StatusBadgeProps> = ({
    status,
}: StatusBadgeProps) => {
    let color: string

    switch (status) {
        case "Avsluttet":
            color = "red"
            break
        case "Pågående":
            color = "teal"
            break
        case "Ikke startet":
            color = "purple"
    }

    return (
        <Badge borderRadius="full" px="2" colorScheme={color}>
            {status}
        </Badge>
    )
}

export default StatusBadge
