import * as React from "react"
import { Box, Flex, Image } from "@chakra-ui/react"
import HeroText from "./HeroText"
import HeroImage from "./HeroImage"

interface HeroProps {
    title: string
    subtitle: string
    image: string
    ctaText: string
    ctaLink: string
    alignment: string
}

const Hero: React.FC<HeroProps> = ({
    title,
    subtitle,
    image,
    ctaLink,
    ctaText,
    alignment,
    ...rest
}: HeroProps): React.ReactElement => {
    const imageBox = <HeroImage image={image} />
    const textStack = (
        <HeroText
            title={title}
            subtitle={subtitle}
            ctaText={ctaText}
            ctaLink={ctaLink}
        />
    )

    const content =
        alignment == "left" ? (
            <>
                {imageBox}
                {textStack}
            </>
        ) : (
            <>
                {textStack}
                {imageBox}
            </>
        )

    return (
        <Flex
            align="center"
            verticalAlign="baseline"
            justify={{
                base: "center",
                md: "space-around",
                xl: "space-between",
            }}
            direction={{ base: "column-reverse", md: "row" }}
            px={8}
            mb={16}
            {...rest}
        >
            {content}
        </Flex>
    )
}

export default Hero
