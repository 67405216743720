import * as React from "react"
import "./CampaignOverviewCard.scss"
import { Campaign } from "../../types/campaign"
import CampaignOverviewCard from "./CampaignOverviewCard"

interface CampaignCardContainerProps {
    campaigns: Campaign[]
}

const CampaignCardContainer: React.FC<CampaignCardContainerProps> = ({
    campaigns,
}: CampaignCardContainerProps) => {
    return (
        <div className="campaignOverviewCards">
            {campaigns?.map((campaign) => (
                <CampaignOverviewCard campaign={campaign} key={campaign.name} />
            ))}
        </div>
    )
}

export default CampaignCardContainer
