import * as React from "react"
import { ReactNode } from "react"
import { Text } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"

interface NavLinkProps {
    children: ReactNode
}

const NavbarLink: React.FC = ({
    children,
}: NavLinkProps): React.ReactElement => {
    const history = useHistory()

    return (
        <div>
            <Text
                px={2}
                py={1}
                rounded={"md"}
                _hover={{ textDecoration: "none", bg: "gray.200" }}
                onClick={() => history.push(children.toString().toLowerCase())}
            >
                {children}
            </Text>
        </div>
    )
}

export default NavbarLink
